<template>
  <div class="zee-container">
    <zee-toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="15000" @clearToast="clearToast" />
    <zee-screen-title :title="'Launch Targets'" :route="'/solutions/zeetv/program-details'" />
    <div class="zee-card-background">
      <div class="d-flex flex-row">
        <div class="zee-multi-tabs-wrapper">
          <zee-tabs title="Number of Weeks" icon="chevron-right">
            <zee-dropdown :options="numOfWeeksDropdown" :selectedOption="selectedWeek" :placeHolder="'Week'"
              @input="handleWeekInput($event)" class="mr-3" />
          </zee-tabs>
          <zee-tabs title="Target GRP" icon="pen">
            <div v-if="isWeekSelected" class="zee-target-grp-dropdowns-wrapper">
              <div class="zee-target-grp-dropdowns-wrapper" v-for="(item, index) in targetGRPDetails" :key="index">
                <zee-input id="grp-input" type="number" :value="item.value" @onChange="handleGRPInput($event, index)"
                  :placeholder="item.name" />
              </div>
            </div>
            <div class="fs-5 d-flex justify-content-center" style="color: red">
              {{ warningMessage }}
            </div>
          </zee-tabs>
          <!-- <div class="ml-5 fs-5 font-weight-bold">Recomended GRP range: 340 - 375</div> -->
        </div>
      </div>
      <!-- <div class="d-none justify-content-end">
        <zee-dropdown
          :options="sortByDropdown"
          :selectedOption="selectedSortType"
          :placeHolder="'Select'"
          @input="handleSortingInput($event)"
          class="mr-3"
        />
      </div> -->
      <div class="zee-campaign-distribution-table-wrapper">
        <div v-if="showTable" class="zee-select-table-radio-1">
          <div class="zee-radios-select" v-for="(item, index) in radios" :key="index">
            <input @input="handleTableSelect" style="width: 15px; height: 15px" type="radio" name="abc"
              :id="'radios ' + index" />
          </div>
        </div>

        <div v-if="showTable">
          <div class="mt-5">
            <div class="zee-table-headings">
              Campaign Distribution Used by Competitions (%)
            </div>
          </div>
          <div class="zee-competitor-campaign-distribution-table mt-5">
            <zee-select-table :tableHeaderData="tableHeaderData" :tableBodyData="tableBodyData" />
            <zee-select-table :tableBodyData="dpRecommededData" />
            <zee-custom-table @onInput="handleRecommendedCustomTable" :customInputData="datapoemRecommended" />
          </div>
        </div>
      </div>
      <div class="zee-program-save-button mt-5">
        <!-- <zee-button @onClick="handleShowTable" title="Show"></zee-button> -->
        <zee-button @onClick="handleSave" title="Next"></zee-button>
      </div>
    </div>
    <page-loader v-if="isLoading"></page-loader>
  </div>
</template>

<script>
import {
  Tabs,
  Dropdown,
  Button,
  Table,
  Input,
  ScreenTitle,
  CustomInputTable,
} from "../components";
import Toast from "@/components/Toast/Toast.vue";
import { zeeTvSolutions } from "../services/zeeTvSolutions";
import PageLoader from "@/widgets/PageLoader.vue";
// import debounce from "lodash.debounce";
// import { filter } from 'vue/types/umd';
const zeeSolutions = new zeeTvSolutions();

export default {
  name: "LaunchTargets",
  components: {
    "zee-tabs": Tabs,
    "zee-dropdown": Dropdown,
    "zee-button": Button,
    "zee-select-table": Table,
    "zee-input": Input,
    "zee-screen-title": ScreenTitle,
    "zee-toast": Toast,
    "zee-custom-table": CustomInputTable,
    "page-loader": PageLoader,
    // "zee-input": Input
  },
  data() {
    return {
      grpConditionSatisfyed: false,
      showWarningMessage: false,
      warningMessage: "",
      isLoading: false,
      dpRecommededData: [
        {
          columns: [
          ]
        }
      ],
      datapoemRecommended: [
        {
          columns: [],
        },
      ],
      isWeekSelected: false,
      tableBodyData: [],
      tableHeaderData: [
        {
          columns: [],
        },
      ],
      targetGRPDetails: [
        {
          name: "min",
          value: "",
        },
        {
          name: "max",
          value: "",
        },
      ],
      numOfWeeksDropdown: [
        { id: 1, text: "1 Week" },
        { id: 2, text: "2 Weeks" },
        { id: 3, text: "3 Weeks" },
        { id: 4, text: "4 Weeks" },
        { id: 5, text: "5 Weeks" },
        { id: 6, text: "6 Weeks" },
        { id: 7, text: "7 Weeks" },
        { id: 8, text: "8 Weeks" },
      ],
      sortByDropdown: [
        { id: 0, text: "Genre" },
        { id: 1, text: "Duration" },
        { id: 2, text: "Year" },
      ],
      radios: [
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
      ],
      abc: "",
      selectedRow: [],
      selectedWeek: {},
      selectedSortType: {},
      programDetails: {},
      showTable: false,
      false: true,
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      maxGRP: -1,
      weeks: -1,
    };
  },
  methods: {
    handleDpRecommendedTable(weeks, maxGrp) {
      zeeSolutions.getDpRecommendedTargets(weeks, maxGrp).then((res) => {
        this.dpRecommededData = [];
        this.radios = [];
        for (var i = 0; i < res.length; i++) {
          this.dpRecommededData.push({ columns: [] });
          for (const property in res[i]) {
            // console.log(res[i][property]);
            if (typeof res[i][property] !== "object")
              this.dpRecommededData[i].columns.push({ name: res[i][property] });
          }
          for (const property in res[i]) {
            if (typeof res[i][property] === "object") {
              // console.log(res[i].weeklyDistribution);
              for (var j = 0; j < weeks; j++) {
                this.dpRecommededData[i].columns.push({
                  name: res[i].weeklyDistribtion[j],
                });
              }
            }
          }
          this.radios.push({
            id: i,
          });
        }
        this.radios.push({
          id: res.length,
        });

      }).catch((err) => {
        alert(err)
      })

    },
    handleRecommendedCustomTable(e) {
      let id = e.id;
      this.datapoemRecommended[id[0]].columns[id[1]].value = e.event.target.value;
      const output = [];
      this.selectedRow = output;
      for (var i = 4; i < this.datapoemRecommended[0].columns.length; i++) {
        output.push(+this.datapoemRecommended[0].columns[i].value.replace("%", ""));
        if (output.length !== 0) {
          this.outputData = output;
        }
      }
    },
    validateCustomInput() {
      let sum = 0;
      console.log(this.selectedRow, "Selected ROw");
      for (let i = 0; i < this.selectedRow.length; i++) {
        let p = parseInt(this.selectedRow[i]) / 100;
        sum += parseInt(this.selectedRow[i]);
        console.log(((+this.targetGRPDetails[1].value - 70) * p), p, this.maxGRP, "Validate custom input");
        if ((+this.targetGRPDetails[1].value - 70) * p >= Math.ceil(this.maxGRP)) {
          this.toastData = {
            show: true,
            type: "edit",
            message: `Per week percentage value will be less than ${Math.ceil(
              (this.maxGRP / (+this.targetGRPDetails[1].value - 70)) * 100
            )}`,
          };
          return false;
        }
      }

      if (sum != 100) {
        this.toastData = {
          show: true,
          type: "edit",
          message: `Custom value sum must be 100`,
        };

        return false;
      }
      return true;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    getMaxGRP() {
      zeeSolutions.getMaxGRP().then((res) => {
        this.maxGRP = res.maxGRP - 20;
      });
    },
    handleGRPInput(e, index) {
      if (+this.targetGRPDetails[0].value > this.maxGRP * this.weeks) {
        this.toastData = {
          show: true,
          message: "Min grp can not be greater than max grp "
        }
        this.grpConditionSatisfyed = true
      } else {
        this.toastData = {
          show: false,
          message: "Min grp can not be greater than max grp "
        }
        this.grpConditionSatisfyed = false

      }
      // console.log(e);
      if (this.targetGRPDetails[index].value != e) {
        this.targetGRPDetails[index].value = e;
      }
      // console.log(+this.targetGRPDetails[0].value);
      if (
        +this.targetGRPDetails[0].value !== 0 &&
        +this.targetGRPDetails[1].value !== 0 &&
        +this.targetGRPDetails[1].value < this.maxGRP * this.weeks
      ) {
        this.showTable = true;
        this.handleDpRecommendedTable(+sessionStorage.getItem("weeks"), +this.targetGRPDetails[1].value)
        // this.handleTableCreation(+sessionStorage.getItem("weeks"), +this.targetGRPDetails[1].value);
      } else {
        this.showWarningMessage = true;
        this.warningMessage = `Max GRP’s possible as per the Historical TVR data is ${Math.round(
          this.maxGRP
        )} per week, For the selected ${this.selectedWeek.id}  weeks is ${Math.round(
          this.maxGRP * this.weeks
        )}.`;
        this.toastData = {
          show: true,
          message: this.warningMessage,
          type: "edit",
        };
      }
    },
    disableButton(data) {
      if (
        data.programNumberOfWeeks !== undefined &&
        data.programTargetGRPs.min !== 0 &&
        data.programTargetGRPs.max !== 0 &&
        data.recommendedDistribution.length !== 0 &&
        data.campaignDistribution.length !== 0 &&
        data.programNumberOfWeeks === data.recommendedDistribution.length &&
        data.programTargetGRPs.max < this.maxGRP * this.weeks && this.grpConditionSatisfyed === false
      ) {
        return true;
      } else {
        return false;
      }
      // if (
      //   data.programNumberOfWeeks !== undefined &&
      //   data.programTargetGRPs.min !== 0 &&
      //   data.programTargetGRPs.max !== 0 &&
      //   data.campaignDistribution !== ""
      // ) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    handleSave() {
      console.log(this.targetGRPDetails)
      let prevData = JSON.parse(localStorage.getItem("programDetails"));
      (prevData[0].programNumberOfWeeks = this.selectedWeek.id),
        (prevData[0].programTargetGRPs = {
          min: +this.targetGRPDetails[0].value,
          max: +this.targetGRPDetails[1].value,
        }),
        (prevData[0].campaignDistribution = this.selectedRow);
      prevData[0].recommendedDistribution = this.selectedRow;

      localStorage.setItem("programDetails", JSON.stringify(prevData));
      if (!this.validateCustomInput()) return;

      if (this.targetGRPDetails[0].value > this.targetGRPDetails[1].value) {
        this.toastData = {
          show: true,
          type: "edit",
          message: `Min GRP value will be less than Max GRP`,
        };
        return;
      }

      let isButtonDisabled = this.disableButton(...prevData);
      if (!isButtonDisabled) {
        if (prevData[0].programTargetGRPs.max > this.maxGRP * this.weeks) {
          this.toastData = {
            show: true,
            type: "edit",
            message: `Max GRP value will be less than ${Math.round(
              this.maxGRP * this.weeks
            )}`,
          };
        } else {
          this.toastData = {
            show: true,
            type: "edit",
            message: "Please fill all the fields with correct values",
          };
        }
      } else {
        this.$router.push({
          name: "ZEETV Select ACDS",
          params: { data: "Launch Targets" },
        });
      }
    },
    handleWeekInput(e) {
      if (e.id !== this.selectedWeek.id) {


        this.toastData = {
          show: true,
          type: "info",
          message: `Weeks changed from ${this.selectedWeek.id} to ${e.id}, Update Max GRP `
        }
      }
      this.selectedWeek = e;
      if (e.id !== undefined) {
        this.isWeekSelected = true;
      }



      this.isTableEmpty = true;
      this.weeks = +e.id;
      sessionStorage.setItem("weeks", e.id);
      console.log(e.id)
      if (e.id !== '') {
        this.handleTableCreation(e.id)
        console.log("max grp value", +this.targetGRPDetails[1].value)
        if (+this.targetGRPDetails[1].value != 0) {
          this.handleDpRecommendedTable(e.id, +this.targetGRPDetails[1].value)
        }
        this.showTable = true
      }
    },
    handleSortingInput(e) {
      this.selectedSortType = e;
    },
    handleTableSelect(e) {
      let id = e.target.id;
      id = +id.split(" ")[1]
      sessionStorage.setItem("selectedRowId", id);
      console.log(id)
      let temp = [];
      for (var i = 4; i < this.dpRecommededData[id].columns.length; i++) {
        temp.push(+this.dpRecommededData[id].columns[i].name);
      }
      this.selectedRow = temp;
      console.log(this.selectedRow, "DSfdsf");
    },
    handleTableCreation(weeks) {
      this.isLoading = true;
      zeeSolutions
        .getCompetitorLaunchTargets(weeks)
        .then((res) => {
          let data = res;
          let selectedGenre = sessionStorage.getItem("selectedGenre");
          selectedGenre === "Fiction"
            ? (selectedGenre = "Fiction")
            : (selectedGenre = "Non Fiction");
          let temp = [];
          let filteredData = data.filter((y) => y.programGenre === selectedGenre);
          console.log(filteredData);
          //for dp recommended
          // filteredData.push({
          //   option: "",
          //   channelName: "NA",
          //   programGenre: "NA",
          //   programName: "NA",
          //   weeklyDistribution: filteredData[2].weeklyDistribution,
          // });
          // filteredData.push({
          //   option: "",
          //   channelName: "NA",
          //   programGenre: "NA",
          //   programName: "NA",
          //   weeklyDistribution: filteredData[0].weeklyDistribution,
          // });
          // filteredData.push()
          this.radios = [];
          for (let a1 = 0; a1 < filteredData.length; a1++) {
            temp.push({
              option: `Option ${a1 + 1}`,
              channelName: filteredData[a1].channelName,
              programGenre: filteredData[a1].programGenre,
              programName: filteredData[a1].programName,
              weeklyDistribution: filteredData[a1].weeklyDistribution,
            });

            // this.radios.push({
            //   id: a1,
            // });
          }
          // this.radios.push({
          //   id: filteredData.length + 1,
          // });
          // temp[temp.length - 2].option = "DP Recommended 1";
          // temp[temp.length - 1].option = "DP Recommended 2";
          this.tableBodyData = [];
          for (var i = 0; i < temp.length; i++) {
            this.tableBodyData.push({ columns: [] });
            for (const property in temp[i]) {
              // console.log(res[i][property]);
              if (typeof temp[i][property] !== "object")
                this.tableBodyData[i].columns.push({ name: temp[i][property] });
            }
            for (const property in filteredData[i]) {
              if (typeof temp[i][property] === "object") {
                // console.log(res[i].weeklyDistribution);
                for (var j = 0; j < weeks; j++) {
                  this.tableBodyData[i].columns.push({
                    name: temp[i].weeklyDistribution[j],
                  });
                }
              }
            }
          }
          this.tableHeaderData[0].columns = [];
          let fields = ["Options", "Program Channel", "Program Name", "Program Genre"];

          for (var m = 0; m < 4; m++) {
            // console.log("fields", fields);
            this.tableHeaderData[0].columns.push({ name: fields[m] });
            // this.datapoemRecommended[0].columns.push({ name: "", id: [0, m] })
          }

          for (var l = weeks; l > 0; l--) {
            this.tableHeaderData[0].columns.push({ name: `Week ${l}` });
            // this.datapoemRecommended[0].columns.push({ name: "", id: [0, l+4] })
          }
          this.datapoemRecommended[0].columns = [];

          for (var k = 0; k < this.tableHeaderData[0].columns.length; k++) {
            this.datapoemRecommended[0].columns.push({ name: "", id: [0, k] });
          }

          for (var g = 0; g < 4; g++) {
            this.datapoemRecommended[0].columns[g].name = "NA";
            this.datapoemRecommended[0].columns[g].input = false;
          }
          this.datapoemRecommended[0].columns[0].name = "Custom";
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err);
          this.isLoading = false;
        });
      // this.$router.push("/solutions/zeetv/recomended-launch-targets");
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    getWeeksDiff(startDate, endDate) {
      const msInWeek = 1000 * 60 * 60 * 24 * 7;

      return Math.round(Math.abs(endDate - startDate) / msInWeek);
    },
    getNumberOfWeeks() {
      let date = sessionStorage.getItem("formattedDate");
      let todaysDate = this.formatDate(new Date());
      // console.log(todaysDate, date);
      let x = this.getWeeksDiff(new Date(date), new Date(todaysDate));
      this.numOfWeeksDropdown = [];

      for (var i = 0; i < x; i++) {
        this.numOfWeeksDropdown.push({ id: i + 1, text: i + 1 });
        // console.log(i);
      }
      // console.log(x);
    },
    retriveData() {
      // this.showTable = true;
      let data = JSON.parse(localStorage.getItem("programDetails"));
      (this.selectedWeek = {
        id: data[0].programNumberOfWeeks,
        text: data[0].programNumberOfWeeks,
      }),
        (this.targetGRPDetails[0].value = data[0].programTargetGRPs.min),
        (this.targetGRPDetails[1].value = data[0].programTargetGRPs.max),
        (this.selectedRow = data[0].campaignDistribution),
        (this.selectedRow = data[0].recommendedDistribution);

      if (+sessionStorage.getItem("weeks") !== 0) {
        this.handleTableCreation(+sessionStorage.getItem("weeks"))
        this.handleDpRecommendedTable(+sessionStorage.getItem("weeks"), +this.targetGRPDetails[1].value)
        this.showTable = true

      }

      // weeks !== null && weeks !== undefined && weeks !== ""
      //   ? this.handleTableCreation(weeks)
      //   : (this.toastData = {
      //       type: "edit",
      //       show: true,
      //       message: "Please select a week.",
      //     });
      // this.showTable = true;
      // let x = +sessionStorage.getItem("selectedRowId");
      // console.log("x", x);
      // document.getElementById(x).checked = true;
    },
  },
  created() {
    this.getMaxGRP();
    this.retriveData();
    this.getNumberOfWeeks();
  },
  updated() {
    let selectedRadio = sessionStorage.getItem("selectedRowId")
    document.getElementById('radios ' + selectedRadio).checked = true
    // document.getElementById(`${selectedRow}`).checked = true
  }
};
</script>

<style>
.zee-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  height: 100%;
}

.zee-card-background {
  background-color: #fafafa;
  width: 90%;
  margin-top: 18px;
  margin-bottom: 54px;
  border-radius: 15px;
}

.zee-multi-tabs-wrapper {
  gap: 3rem;
  width: 100%;
}

.zee-program-save-button {
  display: flex;
  justify-content: flex-end;
}

.zee-weekly-day-selector {
  width: 40%;
  border-radius: 8px;
  background-color: #f2e7ff;
}

.zee-target-grp-dropdowns-wrapper {
  display: flex;
  flex-direction: row;
}

.zee-campaign-distribution-table-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.zee-competitor-campaign-distribution-table {
  margin-top: 28px;
  width: 90%;
  background-color: #f3f3f3;
  border-radius: 20px;
}

.zee-select-table-radio-1 {
  display: flex;
  flex-direction: column;
  margin-right: 2.5rem;
  margin-top: 52rem;
  gap: 3.7rem;
}

.zee-table-headings {
  font-size: 2rem;
  font-weight: bolder;
  color: #000;
  margin-left: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
